import { useNavigate } from 'react-router-dom'
import Logo from '../assets/logo.png'
import { isMobile } from '../shared/utils/javascript';

import IconLupa from '../assets/ICON_LUPA.png'
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../shared/context/auth';
import { Endereco, Veiculo } from '../shared/types';
import { getEnderecos, getEstoque } from '../service/api';

export const Header = () => {

    const navigate = useNavigate();
    const [estoque, setEstoque] = useState<Veiculo[]>([]);

    const verifyRoute = (path: string) => {
        if(path === window.location.pathname) return true
    }

    const handleGetEstoque = async () => {
        try {
            const result = await getEstoque()
            setEstoque(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    const handleSelectChange = (selectedOption: any) => {
        const paramsObj = { 
            busca: selectedOption.value,
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/estoque?params=${paramsString}`;
        navigate(urlComObjeto)
    };

    useEffect(() => {
        handleGetEstoque();
    },[])

    return (
        <div className='mobile:flex mobile:bg-black mobile:justify-center mobile:flex-col mobile:items-center mobile:p-3'>
            <div className="bg-black px-24 py-5 flex items-start flex-col text-accent border-b border-b-[#3b3b3b] mobile:border-none mobile:px-3 mobile:py-3 mobile:justify-between">
                <div className='flex justify-around w-full items-center mobile:justify-center py-3'>
                    <img className='cursor-pointer object-cover max-h-20' onClick={() => navigate('/')} src={Logo} alt="Logo" />
                    {!isMobile() && <div className='flex gap-20 items-center'>
                        <div className='flex justify-between gap-10 min-h-10 items-center'>
                        <div className='text-white flex items-center gap-16'>
                            <h2 onClick={() => navigate('/')} className={`cursor-pointer text-xl ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2>
                            <h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer text-xl ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2>
                            <h2 onClick={() => navigate('/estoque')} className={`cursor-pointer text-xl ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2>
                            <h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer text-xl ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2>
                            <h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer text-xl ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>Avaliação</h2>
                            <h2 onClick={() => navigate('/contato')} className={`cursor-pointer text-xl ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2>
                        </div>
                        <div>
                            <img onClick={() => navigate('/estoque')} className='bg-accent rounded-full p-2 max-h-8 cursor-pointer' src={IconLupa} alt="Pesquisar" />
                        </div>
                    </div>
                    </div>
                    }
                </div>
            </div>
                {isMobile() ? (
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn text-white outline-none border-none"><i className="ri-menu-line ri-3x"></i></div>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-max">
                        <li><h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2></li>
                        <li><h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2></li>
                        <li><h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2></li>
                        <li><h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2></li>
                        <li><h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>Avaliação</h2></li>
                        <li><h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2></li>
                        </ul>
                    </div>
                ) : null}
        </div>
    )
}