import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Cookies = () => {

    const [showButton, setShowButton] = useState(false);
    const navigate = useNavigate()

    function handleClickModal() {
      navigate('/termos')
    }
  
    const handleAcceptCookies = () => {
      localStorage.setItem("cookiesAccepted", true.toString());
      setShowButton(false);
    };
  
    const checkCookiesAccepted = () => {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");
      if (!cookiesAccepted) {
        setShowButton(true);
      }
    };
  
    // Chama checkCookiesAccepted quando o componente for montado
    useEffect(() => {
      checkCookiesAccepted();
    }, []);
    // Renderiza o botão de cookies apenas se showButton for true
    
    if(showButton) {
        return (
            <div className="mx-auto fixed bottom-3 left-3 z-50 mobile:bottom-16">
                <div>
                <div className="w-72 rounded-lg shadow-md p-6" style={{cursor: "auto", background: "linear-gradient(rgb(241, 241, 241) 0%, rgb(255, 255, 255) 99.1%)"}}>
                <div className="w-16 mx-auto relative -mt-10 mb-3">
                    <img className="-mt-1" src="https://www.svgrepo.com/show/30963/cookie.svg" alt="Cookie Icon SVG" />
                </div>
                <span className="w-full sm:w-48  block leading-normal text-gray-800 text-md mb-3">Nós utilizamos cookies para providenciar uma melhor experiência.</span>
                <div className="flex items-center justify-between">
                    <a onClick={handleClickModal} className="text-xs text-gray-400 mr-1 cursor-pointer hover:text-gray-800">Política de privacidade</a>
                    <button onClick={handleAcceptCookies} type="button" className="py-2 px-4 btn btn-primary  rounded-lg">Ok</button>
                </div>
                </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}