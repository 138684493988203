import { useContext, useEffect, useState } from 'react'
import { Telefone as TelefoneDictionary } from '../shared/utils/dictionary'
import { isMobile, scrollToTop } from '../shared/utils/javascript'
import { AuthContext } from '../shared/context/auth'
import { postEmail, postWhats } from '../service/api'
import { Resolver, useForm } from "react-hook-form"
import { toast } from 'react-toastify'
import { Endereco } from '../shared/utils/constants'

type FormValues = {
    motivo: string
    categoria: string
    marca: string
    modelo: string
    anoFabricacao: string,
    anoModelo: string,
    cor: string,
    combustivel: string,
    nome: string,
    email: string,
    celular: string
  }

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
  const resolver: Resolver<FormValues> = async (values) => {
    return {
      values: values.categoria && values.marca && values.modelo && values.anoFabricacao && values.anoModelo && values.cor && values.combustivel && values.nome && values.email && values.celular ? values : {},
      errors: {
        ...(values.categoria ? {} : { categoria: { type: "required", message: "Preencha este campo.", pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/ } }),
        ...(values.marca ? {} : { marca: { type: "required", message: "Preencha este campo." } }),
        ...(values.modelo ? {} : { modelo: { type: "required", message: "Preencha este campo." } }),
        ...(values.anoFabricacao ? {} : { anoFabricacao: { type: "required", message: "Preencha este campo." } }),
        ...(values.anoModelo ? {} : { anoModelo: { type: "required", message: "Preencha este campo." } }),
        ...(values.cor ? {} : { cor: { type: "required", message: "Preencha este campo." } }),
        ...(values.combustivel ? {} : { combustivel: { type: "required", message: "Preencha este campo." } }),
        ...(values.nome ? {} : { nome: { type: "required", message: "Preencha este campo." } }),
        ...(values.email ? {} : { email: { type: "required", message: "Preencha este campo." } }),
        ...(values.email && !emailRegex.test(values.email) ? { email: { type: "pattern", message: "Email inválido" } } : {}),
        ...(values.celular ? {} : { celular: { type: "required", message: "Preencha este campo." } }),
      },
    };
  };

export const Avaliacao = () => {

    const { telefone, ip, email } = useContext(AuthContext);
    const optionsMotivo = ["Motivo da avaliação", "Quero vender meu veículo", "Quero trocar meu veículo", "Quero consignar meu veículo"]
    const [etapa, setEtapa] = useState(0)
    const telefoneWhats = telefone.filter((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.filter((x) => x.whatsapp === "0")
    
    const { register, handleSubmit, setError, clearErrors, formState: { errors }, reset, getValues, watch, setValue } = useForm<FormValues>({ resolver })
    const celularValue = watch("celular");

    const onSubmit = async (data: any) => {
        postEmail({
            to: email,
            replyTo: data.email,
            subject: "Avaliação",
            html: {
                motivo: data.motivo,
                categoria: data.categoria,
                marca: data.marca,
                modelo: data.modelo,
                anoFabricacao: data.anoFabricacao,
                anoModelo: data.anoModelo,
                cor: data.cor,
                combustivel: data.combustivel,
                nome: data.nome,
                email: data.email,
                celular: data.celular,
            },
            lojaId: Number(process.env.REACT_APP_COD_LOJA),
          })
          toast.success('Mensagem enviada com sucesso!')
        setEtapa(0)
        reset()
    };

    const validarPrimeiraEtapa = () => {
        const data: any = getValues();
        
        // Validar se os sete primeiros campos estão preenchidos
        const camposObrigatorios: any = ['categoria', 'marca', 'modelo', 'anoFabricacao', 'anoModelo', 'cor', 'combustivel'];
        
        let algumCampoVazio = false;
        for (let campo of camposObrigatorios) {
          if (!data[campo]) {
            setError(campo, { type: 'required', message: `O campo ${campo} é obrigatório` }); // Definir erro para o campo
            algumCampoVazio = true;
          } else {
            clearErrors(campo); // Limpar o erro se o campo estiver preenchido
          }
        }
        
        if (algumCampoVazio) {
          return false; // Impedir o avanço se algum campo estiver vazio
        }
        
        // Se todos os campos obrigatórios estiverem preenchidos, avançar para a próxima etapa
        setEtapa(1)
        return true;
      };

    useEffect(() => {
    // Aplica a formatação ao valor do campo celular
    if (celularValue) {
        const apenasDigitos = celularValue.replace(/[^\d]/g, ''); // Remove não dígitos
        let celularFormatado = apenasDigitos;

        // Aplica a formatação apenas se houver 11 dígitos
        if (apenasDigitos.length === 11) {
        celularFormatado = apenasDigitos.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        }

        // Define o valor formatado no campo celular
        setValue("celular", celularFormatado, { shouldValidate: true });
    }
    }, [celularValue]);

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    return (
        <div className="px-28 py-16 flex flex-col bg-secondary gap-14 mobile:px-3 mobile:gap-5">
            {/* Titulo */}
            <div className="flex flex-row items-center gap-3">
                <h1 className="min-w-max text-accent font-semibold">{isMobile() ? "FAÇA UMA AVALIAÇÃO CONOSCO" : "INTERESSADO EM VENDER SEU VEÍCULO? FAÇA UMA AVALIAÇÃO CONOSCO"}</h1>
                <hr className="h-0.5 bg-accent w-full" />
            </div>

            {/* Formulário de envio */}
            <form onSubmit={handleSubmit(onSubmit)} className='flex shadow w-fit self-center min-w-[60vw] bg-secondary-content'>
                <div className='p-10 min-w-[30vw] gap-5 flex flex-col mobile:px-3 mobile:py-8 mobile:w-full'>
                        <div className={etapa === 1 ? "hidden" : "p-10 gap-5 flex flex-col mobile:px-3 mobile:py-8 mobile:w-full"}>
                            <h1 className='text-accent'>Preencha o campo, e entraremos em contato</h1>
                            <select {...register("motivo")} className="select select-bordered w-full rounded-none">
                                {optionsMotivo.map((x, i) => (
                                    <option key={i}>{x}</option>
                                ))}
                            </select>

                            <div>
                                <input id='categoria' {...register("categoria")} type="text" placeholder="Categoria" className="input input-bordered w-full rounded-none" />
                                {errors?.categoria && <p className='text-error text-xs absolute'>{errors.categoria.message}</p>}
                            </div>

                            <div>
                                <input id='marca' {...register("marca")} type="text" placeholder="Marca" className="input input-bordered w-full rounded-none" />
                                {errors?.marca && <p className='text-error text-xs absolute'>{errors.marca.message}</p>}
                            </div>

                            <div>
                                <input id='modelo' {...register("modelo")} type="text" placeholder="Modelo" className="input input-bordered w-full rounded-none" />
                                {errors?.modelo && <p className='text-error text-xs absolute'>{errors.modelo.message}</p>}
                            </div>
                            <div className='flex flex-row gap-5'>
                                <div>
                                    <input id='anoFabricacao' {...register("anoFabricacao")} type="text" placeholder="Ano fabricação" className="input input-bordered w-full rounded-none" />
                                    {errors?.anoFabricacao && <p className='text-error text-xs absolute'>{errors.anoFabricacao.message}</p>}
                                </div>
                                <div>
                                    <input id='anoModelo' {...register("anoModelo")} type="text" placeholder="Ano modelo" className="input input-bordered w-full rounded-none" />
                                    {errors?.anoModelo && <p className='text-error text-xs absolute'>{errors.anoModelo.message}</p>}
                                </div>
                            </div>
                            <div className='flex flex-row gap-5'>
                                <div>
                                    <input id='cor' {...register("cor")} type="text" placeholder="Cor" className="input input-bordered w-full rounded-none" />
                                    {errors?.cor && <p className='text-error text-xs absolute'>{errors.cor.message}</p>}
                                </div>
                                <div>
                                    <input id='combustivel' {...register("combustivel")} type="text" placeholder="Combustível" className="input input-bordered w-full rounded-none" />
                                    {errors?.combustivel && <p className='text-error text-xs absolute'>{errors.combustivel.message}</p>}
                                </div>
                            </div>
                            <button className="btn btn-primary font-bold rounded-none" type='button' onClick={() => {validarPrimeiraEtapa()}}>Próxima etapa</button>
                        </div>
                        <div className={etapa === 0 ? "hidden" : "p-10 gap-5 flex flex-col mobile:px-3 mobile:py-8 mobile:w-full"}>
                            <h1 className='text-accent'>Precisamos também dos seus dados pessoais! :)</h1>
                            <div>
                                <input id='nome' {...register("nome")} type="text" placeholder="Nome" className="input input-bordered w-full rounded-none" />
                                {errors?.nome && <p className='text-error text-xs absolute'>{errors.nome.message}</p>}
                            </div>
                            <div>
                                <input id='email' {...register("email")} type="text" placeholder="E-mail" className="input input-bordered w-full rounded-none" />
                                {errors?.email && <p className='text-error text-xs absolute'>{errors.email.message}</p>}
                            </div>
                            <div>
                                <input id='celular' {...register("celular")} type="text" placeholder="Celular" className="input input-bordered w-full rounded-none" />
                                {errors?.celular && <p className='text-error text-xs absolute'>{errors.celular.message}</p>}
                            </div>
                            <div className='grid grid-cols-2 gap-5'>
                                <button className="btn btn-accent btn-outline font-bold rounded-none" onClick={() => setEtapa(0)}>Voltar</button>
                                <button className="btn btn-primary font-bold rounded-none" type='submit'>Enviar mensagem</button>
                            </div>
                            <div className='mt-3 flex flex-col gap-5 text-accent'>
                                <h2>Se tiver algum problema, entre em contato:</h2>
                                <div className='flex gap-5'>
                                    {telefoneWhats.map((x) => (
                                        <div key={x.telefone} className="flex items-center gap-2 cursor-pointer" onClick={() => {
                                            postWhats({
                                                ip: ip,
                                                dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                                lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                                codVeiculo: 0
                                            })
                                            window.open(`https://api.whatsapp.com/send?phone=+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                                        }}>
                                            <i className="ri-whatsapp-line ri-lg"></i>
                                            <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                        </div>
                                    ))}
                                    {telefoneRamal.map((x) => (
                                        <div key={x.telefone} className="flex items-center gap-2 cursor-pointer" onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")}>
                                            <i className="ri-phone-line ri-lg"></i>
                                            <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>                    
                </div>
                {!isMobile() && <div className="w-full">
                        <iframe title='Mapa' src={Endereco.link} width="100%" height="100%" style={{border:"10px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>}
            </form>
        </div>
    )
}